import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { GENERAL_LABEL } from "../../../data/constants";
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager";
import {
  SaveGeneralWorkTimeSlotRequest,
  SaveTimeSlotRequest,
  TimelineContext
} from "../../context/TimelineManager/TimelineManager";
import { FunctionSelect } from "../FunctionSelect/FunctionSelect";
import { ProjectSelect } from "../ProjecSelect/ProjectSelect";
import {
  CloseButtonContainer,
  DrawerInnerContainer,
  DrawerPaperElement,
  InputContainer
} from "./styled";

export const TimeInput: React.FC = () => {
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  const [selectedMinutes, setSelectedMinutes] = useState<null | number>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    (async () => {
      await timelineContext.setProjects();
      await timelineContext.setFunctions();
    })();
  }, []);

  useEffect(() => {
    setDrawerOpen(timelineContext.selectedDay !== null);
  }, [timelineContext.selectedDay]);

  const handleTimeChange = (event: React.ChangeEvent<{ value: any }>) => {
    const splittedTime = event.target.value.split(":");
    const hours = parseInt(splittedTime[0]) as number;
    const minutes = parseInt(splittedTime[1]) as number;
    const selectedMinutes = hours * 60 + minutes;
    setSelectedMinutes(selectedMinutes);
  };

  const handleSaveTime = () => {
    if (!selectedMinutes || !timelineContext.selectedProject) return;

    const functionId = timelineContext.selectedFunction
      ? timelineContext.selectedFunction.id
      : timelineContext.functions.find(
          (f) => f.code === employeeContext.employee.user.functionCode
        )?.id;

    if (
      !functionId &&
      !(timelineContext.selectedProject?.salesNumber === GENERAL_LABEL)
    )
      return;

    timelineContext.unsetFunction();

    let saveTimeSlotRequest: SaveTimeSlotRequest | undefined;
    let saveGeneralWorkTimeSlotRequest:
      | SaveGeneralWorkTimeSlotRequest
      | undefined;

    if (timelineContext.selectedProject.salesNumber === GENERAL_LABEL) {
      saveGeneralWorkTimeSlotRequest = {
        pin: employeeContext.employee.pin,
        duration: selectedMinutes,
        date: timelineContext.selectedDay.date,
        employeeUid: employeeContext.selectedEmployee
          ? employeeContext.selectedEmployee.uid
          : employeeContext.employee.user.uid,
        lookupText: timelineContext.selectedProject.description,
      } as SaveGeneralWorkTimeSlotRequest;
    } else {
      saveTimeSlotRequest = {
        pin: employeeContext.employee.pin,
        orpsId: timelineContext.selectedProject.orpsId,
        duration: selectedMinutes,
        date: timelineContext.selectedDay.date,
        employeeUid: employeeContext.selectedEmployee
          ? employeeContext.selectedEmployee.uid
          : employeeContext.employee.user.uid,
        functionCodeId: functionId,
      } as SaveTimeSlotRequest;
    }

    if (saveTimeSlotRequest) {
      timelineContext.unsetDay();
      timelineContext.addTiming(saveTimeSlotRequest);
    } else if (saveGeneralWorkTimeSlotRequest) {
      timelineContext.unsetDay();
      timelineContext.addTiming(undefined, saveGeneralWorkTimeSlotRequest);
    }
  };

  const handleDrawerClose = () => {
    timelineContext.unsetDay();
    timelineContext.unsetFunction();
    timelineContext.unsetProject();

    setSelectedMinutes(0);
    setDrawerOpen(false);
  };

  const showErrorMessage = () => {
    if (
      !selectedMinutes ||
      !timelineContext.selectedDay ||
      !timelineContext.selectedDay.timeSlots
    ) {
      return false;
    }

    const setTime = timelineContext.selectedDay.timeSlots.reduce(
      (a, b) => a + b.duration,
      0
    );

    if (!timelineContext.selectedDay.totalMinutes) {
      return false;
    }

    return (
      !timelineContext.bookingOverHours &&
      selectedMinutes + setTime > timelineContext.selectedDay.totalMinutes
    );
  };

  const isButtonDisabled = () => {
    if (!timelineContext.selectedProject) {
      return true;
    }

    const functionId = timelineContext.selectedFunction
      ? timelineContext.functions.find(
          (f) => f.code === timelineContext.selectedFunction?.code
        )?.id
      : timelineContext.functions.find(
          (f) => f.code === employeeContext.employee.user.functionCode
        )?.id;

    if (
      !functionId &&
      !(timelineContext.selectedProject?.salesNumber === GENERAL_LABEL)
    ) {
      return true;
    }

    if (!selectedMinutes || selectedMinutes <= 0) {
      return true;
    }

    if (
      !timelineContext.selectedDay ||
      !timelineContext.selectedDay.timeSlots
    ) {
      return true;
    }

    const setTime = timelineContext.selectedDay.timeSlots.reduce(
      (a, b) => a + b.duration,
      0
    );

    if (
      !timelineContext.bookingOverHours &&
      selectedMinutes + setTime > timelineContext.selectedDay.totalMinutes
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {employeeContext.employee && (
        <div style={drawerOpen ? { width: 320 } : {}}>
          <Drawer
            variant="persistent"
            anchor={"left"}
            onClose={handleDrawerClose}
            open={drawerOpen}
            PaperProps={{ component: DrawerPaperElement }}
            style={{
              width: 320,
              flexShrink: 0,
              position: "relative",
              marginLeft: -320,
            }}
          >
            <DrawerInnerContainer>
              <CloseButtonContainer>
                <IconButton onClick={handleDrawerClose}>
                  <Close />
                </IconButton>
              </CloseButtonContainer>
              <Typography variant="h5" gutterBottom>
                Project en tijd opslaan
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {`voor ${
                  employeeContext.selectedEmployee
                    ? employeeContext.selectedEmployee.fullName
                    : employeeContext.employee.user.fullName
                }`}
              </Typography>
              <InputContainer>
                {timelineContext.projects && (
                  <ProjectSelect projects={timelineContext.projects} />
                )}
              </InputContainer>
              <InputContainer>
                <TextField
                  id="time"
                  label="Tijd"
                  type="time"
                  defaultValue="00:00"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 900,
                  }}
                  style={{ width: "100%" }}
                  onChange={handleTimeChange}
                />
              </InputContainer>
              {showErrorMessage() && (
                <div>
                  <FormHelperText error={true}>
                    Het aantal geselecteerde uren mag niet groter zijn dan de
                    resterende beschikbare tijd.
                  </FormHelperText>
                </div>
              )}
              <InputContainer>
                <FunctionSelect />
              </InputContainer>
              <InputContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveTime}
                  disabled={isButtonDisabled()}
                >
                  Opslaan
                </Button>
              </InputContainer>
            </DrawerInnerContainer>
          </Drawer>
        </div>
      )}
    </>
  );
};
