import React, { useEffect } from "react";
import "../../../styles.css";
import { Route, Switch, useHistory } from "react-router-dom";
import { TimelineManager } from "../../context/TimelineManager";
import { EmployeeManager } from "../../context/EmployeeManager";
import { CommentManager } from "../../context/CommentManager";
import { PageManager } from "../../context/PageManager";
import { PageContainerElement } from "./styled";
import { Code } from "../../pages/Code/Code";
import { Done } from "../../pages/Done/Done";
import { Select } from "../../pages/Select/Select";
import { RoutePath } from "../../../data/RoutePath";
import { AuthProvider, AuthProviderProps, useAuth } from "oidc-react";
import { History } from 'history';

export const App: React.FC = () => {
  const history = useHistory();

  const oidcConfig: AuthProviderProps = {
    onSignIn: () => {
      history.push(RoutePath.Code)
    },
    authority: process.env.REACT_APP_IDENTITY_AUTHORITY_URL,
    clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirectUri: process.env.REACT_APP_IDENTITY_REDIRECT_URI,
    scope: process.env.REACT_APP_IDENTITY_SCOPE,
    responseType: "code",
    automaticSilentRenew: true,
    silentRedirectUri: process.env.REACT_APP_IDENTITY_SILENT_REDIRECT_URI,
  };

  /**
 * When user starts on another page than the start page,
 * redirect to the starting page.
 */
  useEffect(() => {
    if (
      history.location.pathname !== RoutePath.Code &&
      !history.location.pathname.includes("authentication")
    ){
      history.push(RoutePath.Code);
    }
  }, []);
  

  return (
    <AuthProvider {...oidcConfig}>
      <UserContainer history={history}/>
    </AuthProvider>
  );
};


const UserContainer: React.FC<{history: History}> = ({history}) => {
  const auth = useAuth();

  return (
    <>
      {auth?.userData && (
        <PageManager history={history}>
          <EmployeeManager>
            <TimelineManager>
              <CommentManager>
                <Route path={RoutePath.Code} exact component={Code} />
                <Route path={RoutePath.Select} exact>
                  <PageContainerElement>
                    <Select />
                  </PageContainerElement>
                </Route>
                <Route path={RoutePath.Done} exact>
                  <PageContainerElement>
                    <Done />
                  </PageContainerElement>
                </Route>
              </CommentManager>
            </TimelineManager>
          </EmployeeManager>
        </PageManager>
      )}
    </>
  );
}

export default App;

