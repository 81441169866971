import React, { useState, useEffect, useContext } from "react";
import {
  ContainerElement,
  TimeContainerElement,
  TotalTimeElement,
  TodoTimeElement,
  DoneIconContainer,
  DateLabelElement
} from "./styled";
import Timing from "../Timing";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import { DayType } from "../../context/TimelineManager/TimelineManager";
import { TimelineContext } from "../../context/TimelineManager/TimelineManager";
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager";

type Times = {
  todoHours: number;
  todoMinutes: number;
  totalHours: number;
  totalMinutes: number;
};

export const Day: React.FC<DayType> = ({ date, totalMinutes, timeSlots }) => {
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  const today = new Date();
  const yesterday =  new Date(new Date().setDate(today.getDate() - 1));
  const dayDate = new Date(date);
  const days = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"];

  const [times, setTimes] = useState<Times>({
    todoHours: 0,
    todoMinutes: 0,
    totalHours: 0,
    totalMinutes: 0
  });

  const timeToHeight = (m: number): number =>
    (((m * 100) / totalMinutes) * 1000) / 100;

  useEffect(() => {
    let times = {
      todoHours: Math.floor(
        (totalMinutes - timeSlots.reduce((a, b) => a + b.duration, 0)) / 60
      ),
      todoMinutes: Math.round(
        (((totalMinutes - timeSlots.reduce((a, b) => a + b.duration, 0)) / 60) %
          1) *
          60
      ),
      totalHours: Math.floor((totalMinutes - 0) / 60),
      totalMinutes: Math.round((((totalMinutes - 0) / 60) % 1) * 60)
    };
    setTimes(times);

    if(employeeContext.employee.admin){
      employeeContext.updateWorkStatus(dayDate.getDay() === today.getDay() ? 0 : dayDate.getDay() === yesterday.getDay() ? 1 : 2, totalMinutes, timeSlots.reduce((a, b) => a + b.duration, 0));
    }
  }, [timeSlots, totalMinutes, timelineContext.timeline]);

  const handleAddTiming = (overhours: boolean) => {
    timelineContext.setDay(date);
    timelineContext.setBookingOverhours(overhours);
  };

  const isOverhour = (id: number): boolean => {
    const sortedTimeslots = timeSlots.sort((a, b) => b.id - a.id);
    const index = sortedTimeslots.findIndex(t => t.id === id);
    const timeBeforeIndex = sortedTimeslots.filter((_, idx) => idx > index);
    return timeBeforeIndex.reduce((a, b) => a + b.duration, 0) + sortedTimeslots[index].duration > totalMinutes;
  }

  return (
    <ContainerElement>
      <DateLabelElement>
        {dayDate.getDay() === today.getDay() ? "Vandaag" : days[dayDate.getDay()]}
      </DateLabelElement>
      <TimeContainerElement>
        <TotalTimeElement>
          {`${times.totalHours > 0 ? `${times.totalHours} uur` : ""} ${
            times.totalMinutes > 0
              ? `${times.totalHours > 0 ? "en" : ""} ${times.totalMinutes} min`
              : ""
          }`}
        </TotalTimeElement>
        <TodoTimeElement>
          {times.todoHours <= 0 && times.todoMinutes <= 0
            ? "Voltooid"
            : `nog ${times.todoHours > 0 ? `${times.todoHours} uur` : ""} ${
                times.todoMinutes > 0
                  ? `${times.todoHours > 0 ? "en" : ""} ${
                      times.todoMinutes
                    } min`
                  : ""
              }`}
        </TodoTimeElement>
      </TimeContainerElement>
      {/* Create empty item to fill todo time. */}
      <div
        style={{
          minHeight: 65,
          height: timeToHeight(
            totalMinutes - timeSlots.reduce((a, b) => a + b.duration, 0)
          ),
          display: "flex",
          justifyContent: "center"
        }}
      >
        {(times.todoHours > 0 || times.todoMinutes > 0) && (
          <IconButton
            style={{ background: " #E8F0FD", width: 56, height: 56 }}
            color="primary"
            aria-label="add time"
            onClick={() => handleAddTiming(false)}
          >
            <AddIcon />
          </IconButton>
        )}
        {times.todoHours <= 0 && times.todoMinutes <= 0 && (
          <DoneIconContainer>
            <DoneIcon />
          </DoneIconContainer>
        )}
        {employeeContext.employee.admin && (times.todoHours <= 0 && times.todoMinutes <= 0) && (
          <IconButton
            style={{ background: "#ffedb8", color: "#ff9800", marginLeft: 15, width: 56, height: 56 }}
            color="primary"
            aria-label="add time"
            onClick={() => handleAddTiming(true)}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      {timeSlots.sort((a, b) => b.id - a.id).map((timing, idx) => (
        <Timing
          id={timing.id}
          key={`${timing.orpsId}-${idx}`}
          description={timing.description}
          duration={timing.duration}
          orpsId={timing.orpsId}
          height={timeToHeight(timing.duration)}
          overhour={isOverhour(timing.id)}
          timeSlotType={timing.timeSlotType}
        />
      ))}
    </ContainerElement>
  );
};
