import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {useAuth} from "oidc-react";

type CommentManagerProps = {
  children: React.ReactNode;
};

export type LookupCommentType = {
  id: number;
  uid: string;
  lookupText: string;
};

export type CustomCommentType = LookupCommentType & {
  customText: string;
  timeStamp: Date;
  orpsId: number;
};

export type SaveCommentRequest = {
  pin: string;
  employeeUid: string;
  orpsId: number;
  lookupText: string;
  customText: string;
};

export type DeleteCommentRequest = {
  pin: string;
  employeeUid: string;
  orpsId: number;
  commentId: number;
};

type CommentResponseType = {
  customComments: CustomCommentType[] | null;
  lookupComments: LookupCommentType[] | null;
  orpsId: number;
};

type CommentManagerState = {
  lookupComments: LookupCommentType[];
  customComments: CustomCommentType[];
  getComments: (orps: number) => Promise<CustomCommentType[] | undefined>;
  saveComment: (request: SaveCommentRequest) => any;
  deleteComment: (request: DeleteCommentRequest) => any;
};

const defaultComment = {
  lookupComments: [],
  customComments: [],
  getComments: () => {
    throw new Error("Context not initialized.");
  },
  saveComment: () => {
    throw new Error("Context not initialized.");
  },
  deleteComment: () => {
    throw new Error("Context not initialized.");
  },
};

export const CommentContext = React.createContext<CommentManagerState>(
  defaultComment
);

export const CommentManager: React.FC<CommentManagerProps> = ({ children }) => {
  const auth = useAuth();
  const [customCommentsState, setCustomCommentsState] = useState<
    CustomCommentType[] | null
  >(null);
  const [lookupCommentsState, setLookupCommentsState] = useState<
    LookupCommentType[] | null
  >(null);

  /**
   * Fetches the comment of an ORPS.
   */
  const fetchComments = async (
    orps: number
  ): Promise<CommentResponseType | undefined> => {
    const token = await auth?.userData?.access_token;

    const response = await fetch(`/api/Comment/${orps}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) return undefined;
    return await response.json();
  };

  /**
   * Gets all the available comments of an ORPS.
   */
  const getComments = async (
    orps: number
  ): Promise<CustomCommentType[] | undefined> => {
    const fetchedComments = await fetchComments(orps);
    if (!fetchedComments) return undefined;
    const lookupComments = fetchedComments.lookupComments?.map((c) => ({
      ...c,
      uid: uuidv4(),
    })) as LookupCommentType[];
    setLookupCommentsState(lookupComments);

    return fetchedComments.customComments?.map((c) => ({
      ...c,
      orpsId: orps,
    })) as CustomCommentType[];
  };

  /**
   * Fetch the api to remove a comment.
   * @param request DeleteCommentRequest
   */
  const fetchDeleteComment = async (request: DeleteCommentRequest) => {
    const token = await auth?.userData?.access_token;
    const response = await fetch("/api/Comment/delete", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok)
      throw new Error("Something went wrong while deleting comment.");
  };

  const deleteComment = async (request: DeleteCommentRequest) => {
    await fetchDeleteComment(request);

    // Refresh comments since one is deleted from applicable list,
    // So it can be available again.
    await getComments(request.orpsId);
  };

  /**
   * Fetch the api to save a comment.
   * @param request SaveCommentRequest
   */
  const fetchSaveComment = async (request: SaveCommentRequest) => {
    const token = await auth?.userData?.access_token;

    const response = await fetch("/api/Comment/save", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok)
      throw new Error("Something went wrong while saving comment.");
  };

  const saveComment = async (request: SaveCommentRequest) => {
    await fetchSaveComment(request);
  };

  return (
    <CommentContext.Provider
      value={{
        customComments: customCommentsState as CustomCommentType[],
        lookupComments: lookupCommentsState as LookupCommentType[],
        deleteComment: deleteComment,
        saveComment: saveComment,
        getComments: getComments,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};
