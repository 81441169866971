import React, { useState, useContext, useEffect } from "react";
import {
  ContainerElement,
  TitleElement,
  OrpsElement,
  TimeElement,
  MoreIconAnchorElement,
} from "./styled";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  TimelineContext,
  DeleteTimeSlotRequest,
} from "../../context/TimelineManager/TimelineManager";
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager";
import { Typography } from "@material-ui/core";
import { ProjectType } from "../../context/TimelineManager/TimelineManager";

type TimingProps = {
  id: number;
  description: string;
  duration: number;
  orpsId?: number;
  height: number;
  overhour: boolean;
  timeSlotType: number;
};

export const Timing: React.FC<TimingProps> = ({
  id,
  description,
  duration,
  orpsId,
  height,
  overhour,
  timeSlotType,
}) => {
  const restHours: number = Math.floor(duration / 60);
  const restduration: number = Math.round(((duration / 60) % 1) * 60);
  const [menuOpen, setMenuOpen] = useState(false);
  const [moreAnchor, setMoreAnchor] = useState<null | HTMLElement>(null);
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  const [project, setProject] = useState<ProjectType | null>(null);

  useEffect(() => {
    const projectFound = timelineContext.projects?.find(
      (p) => p.orpsId === orpsId
    );

    if (!projectFound) return;

    setProject(projectFound);
  }, [timelineContext.projects]);

  const handleMoreClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setMoreAnchor(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleCloseMore = () => {
    setMenuOpen(false);
  };

  const handleDeleteClick = () => {
    const deleteRequest: DeleteTimeSlotRequest = {
      pin: employeeContext.employee.pin,
      employeeUid: employeeContext.selectedEmployee
        ? employeeContext.selectedEmployee.uid
        : employeeContext.employee.user.uid,
      id: id,
    };

    timelineContext.removeTiming(deleteRequest, timeSlotType);
    handleCloseMore();
  };

  return (
    <ContainerElement height={height} overhour={overhour} timeSlotType={timeSlotType}>
      <Menu
        id={`${description}-menu`}
        anchorEl={moreAnchor}
        keepMounted
        open={menuOpen}
        onClose={handleCloseMore}
      >
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon
            style={{
              marginRight: 15,
              color: "#757575",
            }}
          />
          Verwijderen
        </MenuItem>
      </Menu>
      <MoreIconAnchorElement onClick={(e) => handleMoreClick(e)}>
        <MoreHorizIcon />
      </MoreIconAnchorElement>
      <TitleElement>
        <Typography variant="h6" noWrap>
          {description}
        </Typography>
      </TitleElement>
      <OrpsElement>
        <Typography variant="subtitle1" noWrap>
          {project?.salesNumber} - {project?.salesPosition}
        </Typography>
        {orpsId && 
          <Typography variant="subtitle1" noWrap>
            {orpsId}
          </Typography>
        }
      </OrpsElement>
      <TimeElement>
        <Typography variant="subtitle2" noWrap>
          {`${restHours > 0 ? `${restHours} uur` : ""} ${
            restduration > 0
              ? `${restHours > 0 ? "en" : ""} ${restduration} min`
              : ""
          }`}
        </Typography>
      </TimeElement>
    </ContainerElement>
  );
};
