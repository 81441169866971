import styled from "styled-components/macro";

export type CircleProps = Partial<{
    wrongCode: boolean;
    correctCode: boolean;
    filled: boolean;
}>;

export const ContainerElement = styled.div`
    display: flex;
    color: #1465D8;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const InputContainerElement = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
`;

export const DotsElement = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
`;

export const DotElement = styled.li`
    border-radius: 25px;
    width: 20px;
    height: 20px;
    margin: 10px;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const CircleElement = styled.div<CircleProps>`
    position: absolute;
    border: 2px solid #1465D8;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    animation: circleFill .06s ease-in-out;
    ${({filled}) => filled && `
        background: #1465D8;
        border-color: #1465D8;
    `}
    ${({wrongCode}) => wrongCode && `
        background: #F44336;
        border-color: #F44336;
    `}
    ${({correctCode}) => correctCode && `
        background: #388E3C;
        border-color: #388E3C;
    `}
`;

export const HiddenInputElement = styled.div`
    opacity: 0;
`;