import styled from "styled-components/macro";

export const ContainerElement = styled.div`

`;

export const ProjectOptionElement = styled.div`
    flex-direction: column;
    width: 100%;
`;

export const ProjectInnerListContainer = styled.div`
    margin: 0 25px;
    border-left: 2px solid #ccc9c9;
`;

export const SelectedItemContainer = styled.div`
    text-align: left;
    margin-top: 10px;
    max-width: 300px;
`;