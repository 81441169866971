import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormHelperText,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import { green, red, amber } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import Alert from "@material-ui/lab/Alert";
import { ReportProblem } from "@material-ui/icons";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useEffect, useState } from "react";
import {
  CommentContext,
  CustomCommentType,
  DeleteCommentRequest,
  LookupCommentType,
  SaveCommentRequest,
} from "../../context/CommentManager";
import { EmployeeContext } from "../../context/EmployeeManager";
import { TimelineContext } from "../../context/TimelineManager";
import { Comments } from "../Comments/Comments";
import { ProjectSelect } from "../ProjecSelect/ProjectSelect";

import {
  ContainerElement,
  InputContainerElement,
  ButtonContainerElement,
  ButtonHolderElement,
  CommentProjectSelectContainer,
} from "./styled";

const CustomColorSwitch = withStyles({
  switchBase: {
    color: red[500],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[300],
    },
  },
  checked: {},
  track: {
    backgroundColor: red[300],
  },
})(Switch);

const useStyles = makeStyles({
  fab: {
    color: "#FFFFFF",
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[600],
    },
  }
});

export const Comment: React.FC = () => {
  const commentContext = useContext(CommentContext);
  const employeeContext = useContext(EmployeeContext);
  const timelineContext = useContext(TimelineContext);
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [
    selectedComment,
    setSelectedComment,
  ] = useState<LookupCommentType | null>(null);
  const [otherInputValue, setOtherInputValue] = useState<string>("");
  const [otherError, setOtherError] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [customComments, setCustomComments] = useState<CustomCommentType[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOtherInputValue("");
    setOtherError(false);
    setDialogOpen(false);
    setSelectedComment(null);
    timelineContext.unsetProjects();
  };

  const handleSave = () => {
    if (otherInputValue.length > 2 && selectedComment) {
      handleSaveComment(selectedComment?.uid);
    } else {
      setOtherError(true);
      return;
    }

    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 2500);

    handleClose();
  };

  const handleClickComment = (comment: LookupCommentType) => {
    setSelectedComment(comment);
    setDialogOpen(true);
  };

  const handleOtherInputChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setOtherInputValue(event.target.value as string);
  };

  const handleDeleteComment = (id: number, orpsId: number) => {
    const deleteCommentRequest = {
      pin: employeeContext.employee.pin,
      employeeUid: employeeContext.employee.user.uid,
      orpsId: orpsId,
      commentId: id,
    } as DeleteCommentRequest;

    commentContext.deleteComment(deleteCommentRequest);
  };

  const handleSaveComment = (uid: string) => {
    timelineContext.selectedProjects.forEach((p) => {
      const saveCommentRequest = {
        pin: employeeContext.employee.pin,
        employeeUid: employeeContext.employee.user.uid,
        orpsId: p.orpsId,
        lookupText: commentContext.lookupComments.find(
          (c: LookupCommentType) => c.uid === uid
        )?.lookupText,
        customText: otherInputValue,
      } as SaveCommentRequest;

      commentContext.saveComment(saveCommentRequest);
    });
  };

  useEffect(() => {
    (async () => {
      if (timelineContext.selectedProjects) {
        const commentPromises = await timelineContext.selectedProjects.map(
          async (p) => await commentContext.getComments(p.orpsId)
        );
        const comments = await Promise.all(commentPromises);

        if (!comments) return;

        // Use a filter on comments because when their are no comments in db, the comments array will be [undefined].
        setCustomComments(
          comments.filter((c) => c).flat() as CustomCommentType[]
        );
      }
    })();
  }, [timelineContext.selectedProjects]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const classes = useStyles();
  const matches = useMediaQuery('(max-width:768px)');
  return (
    <ContainerElement flex-direction={matches ? "row" : "column"}>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{selectedComment?.lookupText}</DialogTitle>
        <DialogContent>
          <InputContainerElement>
            <FormControl error={otherError}>
              <TextField
                style={{ width: 300 }}
                variant="outlined"
                label="Toelichting"
                onChange={handleOtherInputChange}
              />
              {otherError && (
                <FormHelperText>
                  Vul een toelichting in van minimaal 3 karakters.
                </FormHelperText>
              )}
            </FormControl>
          </InputContainerElement>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            annuleren
          </Button>
          <Button autoFocus onClick={handleSave} color="primary">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={alertOpen}>
        <Alert variant="filled" severity="success">
          De verstoringen zijn opgeslagen!
        </Alert>
      </Collapse>

      { matches ?
      <Fab className={classes.fab} onClick={handleClickOpen} size="medium">
          <ReportProblem />
      </Fab>
      :
      <Button onClick={handleClickOpen} color="primary">
        Verstoring melden
      </Button>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ style: { marginBottom: 115 } }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Verstoringen melden"}
        </DialogTitle>
        <DialogContent>
          <CommentProjectSelectContainer>
            <ProjectSelect
              projects={timelineContext.projects}
              selectedProject={timelineContext.selectedProject}
              multiselect
            />
          </CommentProjectSelectContainer>
          {timelineContext.selectedProjects &&
            timelineContext.selectedProjects.length > 0 && (
              <>
                <ButtonHolderElement>
                  {commentContext.lookupComments?.map((c, idx) => (
                    <ButtonContainerElement>
                      <Button
                        style={{ width: "100%" }}
                        key={idx}
                        onClick={() => handleClickComment(c)}
                        variant="outlined"
                        color="primary"
                      >
                        {c.lookupText}
                      </Button>
                    </ButtonContainerElement>
                  ))}
                </ButtonHolderElement>
                <Comments
                  onDeleteComment={handleDeleteComment}
                  customComments={customComments}
                />
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Annuleren
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerElement>
  );
};
