import TextField from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect } from "react";
import { Day } from "../Day/Day";
import { Comment } from "../Comment/Comment";
import { 
  ContainerElement, 
  EmployeeSelectContainer, 
  LifelineElement, 
  EmployeeSelectElement, 
  WelcomeElement,
  WelcomeBox,
  WelcomeBoxAdmin,
  WelcomeBlock,
  Block
} from "./styled";
import { TimelineContext } from "../../context/TimelineManager/TimelineManager"; 
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager"; 
import { EmployeeSelect } from '../EmployeeSelect/EmployeeSelect';

export const Timeline: React.FC = () => {
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  
  useEffect(() => {
    (async () => {
      if(!employeeContext.employee.admin){
        await timelineContext.getTimeline(employeeContext.employee.pin, employeeContext.employee.user.uid);
      }
    })();
  }, []);

  return (
    <ContainerElement>
      {employeeContext.employee && employeeContext.employee.admin ?
        <WelcomeBoxAdmin>
          <WelcomeElement>
            Welkom {employeeContext.employee.user.fullName} <span role="img" aria-label="zwaaiende hand">👋</span>
          </WelcomeElement>
        </WelcomeBoxAdmin>
        :
        <WelcomeBlock>
          <Block />
          <WelcomeBox>
            <WelcomeElement>
              Welkom {employeeContext.employee.user.fullName} <span role="img" aria-label="zwaaiende hand">👋</span>
            </WelcomeElement>
          </WelcomeBox>
        </WelcomeBlock>      
      }  

      <Comment/>
      {employeeContext.employee && employeeContext.employee.admin && 
        <EmployeeSelect/>
      }
      <LifelineElement />
      {(!employeeContext.employee.admin || employeeContext.selectedEmployee) && timelineContext.timeline &&
        timelineContext.timeline.map((day, idx) => (
          <Day
            date={day.date}
            totalMinutes={day.totalMinutes}
            timeSlots={day.timeSlots}
          />
        ))}
    </ContainerElement>
  );
};
