import styled from "styled-components/macro";
import { TimeslotTypes } from "../../context/TimelineManager";

export type ContainerProps = Partial<{
  height: number;
  overhour: boolean;
  timeSlotType: TimeslotTypes;
}>;

export const ContainerElement = styled.div<ContainerProps>`
  display: flex;
  background: ${props => props.overhour ? `#ffedb8` : props.timeSlotType === TimeslotTypes.ExtraWork ? '#a5d6a7' : '#E8F0FD'};
  color: ${props => props.overhour ? `#ff9800` : props.timeSlotType === TimeslotTypes.ExtraWork ? '#388e3c' : '#1465D8'};
  min-height: 120px;
  width: 200px;
  flex-direction: column;
  margin-top: 15px;
  padding: 10px;
  border-left: 3px solid ${props => props.overhour ? `#ff9800` : props.timeSlotType === TimeslotTypes.ExtraWork ? '#388e3c' : '#1465D8'};
  position: relative;
  border-radius: 0 5px 5px 0;
  height: ${props => `${props.height}px`|| '0px'};
`;

export const TitleElement = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  max-width: 175px;
`;

export const OrpsElement = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

export const TimeElement = styled.span`
  font-size: 14px;
  position: absolute;
  bottom: 10px;
`;

export const MoreIconAnchorElement = styled.a`
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
`;