import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Warning from "@material-ui/icons/Warning";
import React, { useEffect, useState } from "react";
import { CustomCommentType } from "../../context/CommentManager";
import {
  ContainerElement,
  IncidentContainerElement,
  IncidentItemElement,
  SummaryIconElement,
} from "./styled";

type CommentsType = {
  customComments: CustomCommentType[];
  onDeleteComment: (id: number, orpsId: number) => void;
};

export const Comments: React.FC<CommentsType> = ({
  customComments,
  onDeleteComment,
}) => {
  const [customCommentsState, setCustomCommentsState] = useState(
    customComments
  );

  const handleDeleteComment = (id: number, text: string, orpsId: number) => {
    if (
      window.confirm(
        `Weet je zeker dat je de toelichting '${text}' wilt verwijderen?`
      )
    ) {
      const appliedCommentsCopy = Object.assign(
        [],
        customCommentsState
      ) as CustomCommentType[];
      const commentIndex = appliedCommentsCopy.findIndex((c) => c.id === id);
      appliedCommentsCopy.splice(commentIndex, 1);
      setCustomCommentsState(appliedCommentsCopy);

      onDeleteComment(id, orpsId);
    }
  };

  useEffect(() => {
    setCustomCommentsState(customComments);
  }, [customComments]);

  return (
    <ContainerElement>
      {customCommentsState.length === 0 && (
        <Typography variant="subtitle2" gutterBottom>
          Nog geen toelichtingen gemeld.
        </Typography>
      )}
      {customCommentsState.length > 0 && (
        <IncidentContainerElement>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <SummaryIconElement>
                <Warning style={{ color: orange[500] }} />
              </SummaryIconElement>
              <Typography variant="subtitle1">Verstoringen</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List dense style={{ width: "100%" }}>
                {customCommentsState.map((c, idx) => (
                  <IncidentItemElement key={idx}>
                    <ListItem>
                      <ListItemText
                        secondary={new Date(c.timeStamp).toDateString()}
                        primary={`${c.lookupText}, ${c.customText}`}
                      ></ListItemText>
                      <IconButton
                        onClick={() =>
                          handleDeleteComment(c.id, c.customText, c.orpsId)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                    <Divider />
                  </IncidentItemElement>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </IncidentContainerElement>
      )}
    </ContainerElement>
  );
};
