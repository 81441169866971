import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
  align-items: center;
  margin: 25px;
  z-index: 5;
  position: fixed;
  right: 0;
  bottom: 75px;
`;

export const InputContainerElement = styled.div`
  display: block;
`;

export const ButtonContainerElement = styled.div`
  margin: 5px;
  width: 100%;
`;

export const ButtonHolderElement = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

export const CommentProjectSelectContainer = styled.div`
  width: 300px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
