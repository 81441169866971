import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  z-index: 2;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 30px 0 rgba(31, 51, 73, 0.1);
  height: 1000px;
  min-width: 222px;
  overflow: scroll;
`;

export const TimeContainerElement = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const TotalTimeElement = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #6a6f6a;
  margin-bottom: 5px;
`;

export const TodoTimeElement = styled.span`
  font-size: 14px;
  color: #adadad;
`;

export const DoneIconContainer = styled.div`
  background: #e8f0fd;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1465d8;
`;

export const DateLabelElement = styled.div`
    position: absolute;
    margin-top: -75px;
    color: #6a6f6a;
    margin-left: 20px;
`;
