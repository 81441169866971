import styled, { createGlobalStyle } from "styled-components/macro";
import { normalize } from "./normalize";

export const GlobalStyle = createGlobalStyle<{ gridSize: number }>`
  ${normalize}

  html,
  body {
    /* Grid size is defined using the font-size */
    font-size: 16px;
  }

  body {
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: black;
    overflow-x: hidden;

    transition: background .5s;
  }

  * {
    box-sizing: border-box;
  }

  svg {
    fill: currentColor;
    min-width: 24px;
    min-height: 24px;
  }
`;

export const PageContainerElement = styled.div`
    min-height: 100vh;
    width: 100%;
    transition: .3s ease-in-out;
    position: absolute;
`;