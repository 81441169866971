import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  width: 100%;
`;

export const LifelineElement = styled.div`
  background: #e3e4e4;
  width: 2px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
`;

export const EmployeeSelectElement = styled.input`
  border: none;
  padding: 15px;
  font-size: 16px;
  z-index: 2;
  border-radius: 50px;
  outline: none;
  color: #6a6f6a;
  background: white;
  width: 250px;
  box-shadow: 0 0 30px 0 rgba(31, 51, 73, 0.1);
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #adadad;
  }
  :-ms-input-placeholder {
    color: #adadad;
  }
`;

export const EmployeeSelectContainer = styled.div`
  z-index: 2;
  margin-bottom: 45px;
`;

export const WelcomeElement = styled.div`
  text-align: center;
`;

export const WelcomeBox = styled.div`
  z-index: 2;
  background: white;
  margin-bottom: 40px;
  border-radius: 5px;
  padding: 25px 55px;
  box-shadow: 0 0 30px 0 rgb(31 51 73 / 10%);
`;

export const WelcomeBoxAdmin = styled.div`
  background-color: #f6f6f6;
  z-index: 2;
  padding: 25px 0px;
`;

export const Block = styled.div`
  background-color: #f6f6f6;
  z-index: 1;
  padding: 12.5px;
`;

export const WelcomeBlock = styled.div`
  z-index: 2;
`;
