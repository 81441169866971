import { green, grey, orange, red } from "@material-ui/core/colors";
import {
  Close as CloseIcon,
  Done as DoneIcon,
  DoneAll as DoneAllIcon,
  Remove as RemoveIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import React from "react";
import { WorkHourStatus } from "../../context/EmployeeManager/EmployeeManager";
import { LabelELement } from "./styled";

interface EmployeeStatusColumnsProps {
  status: WorkHourStatus;
  day: string | JSX.Element;
}

export const EmployeeStatusColumns: React.FC<EmployeeStatusColumnsProps> = ({
  status,
  day,
}) => {
  return (
    <>
      <LabelELement>{day}</LabelELement>
      {status == WorkHourStatus.NotApplicable && (
        <RemoveIcon style={{ color: grey[500] }} />
      )}
      {status === WorkHourStatus.NotStarted && (
        <CloseIcon style={{ color: red[500] }} />
      )}
      {status === WorkHourStatus.Partial && (
        <WarningIcon style={{ color: orange[500] }} />
      )}
      {status === WorkHourStatus.Complete && (
        <DoneIcon style={{ color: green[500] }} />
      )}
      {status === WorkHourStatus.OverHours && (
        <DoneAllIcon style={{ color: green[500] }} />
      )}
    </>
  );
};
