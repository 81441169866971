import { TextField } from "@material-ui/core";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect, useState } from "react";
import {
  EmployeeContext,
  WorkHourStatus,
} from "../../context/EmployeeManager/EmployeeManager";
import { TimelineContext } from "../../context/TimelineManager/TimelineManager";
import { EmployeeStatusColumns } from "../EmployeeStatusColumns/EmployeeStatusColumns";
import {
  EmployeeOptionColumnElement,
  EmployeeOptionElement,
  EmployeeOptionNameColumnElement,
  EmployeeSelectContainer,
  LabelELement,
} from "./styled";

export const EmployeeSelect: React.FC = () => {
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  const days = [
    "Zondag",
    "Maandag",
    "Dinsdag",
    "Woensdag",
    "Donderdag",
    "Vrijdag",
    "Zaterdag",
  ];
  const [workDays, setWorkDays] = useState<number[]>([]);

  useEffect(() => {
    const today = new Date();

    const previousWorkDay =
      today.getDay() === 1
        ? new Date(new Date().setDate(today.getDate() - 3))
        : new Date(new Date().setDate(today.getDate() - 1));

    const dayBeforePreviousWorkDay =
      previousWorkDay.getDay() === 1
        ? new Date(
            new Date(previousWorkDay).setDate(previousWorkDay.getDate() - 3)
          )
        : new Date(
            new Date(previousWorkDay).setDate(previousWorkDay.getDate() - 1)
          );

    setWorkDays([previousWorkDay.getDay(), dayBeforePreviousWorkDay.getDay()]);
  }, []);

  const handleSelectChange = async (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "select-option") {
      const uid = value.uid as string;
      // Set the selected employee in the state.
      // So it can be used to fetch the timeline.
      employeeContext.setSelectedEmployee(uid);
      const employee = employeeContext.employee;
      await timelineContext.getTimeline(employee.pin, uid);
      timelineContext.unsetDay();
    }
    if (reason === "clear") {
      employeeContext.unsetSelectedEmployee();
      timelineContext.unsetDay();
    }
  };

  return (
    <EmployeeSelectContainer>
      <Autocomplete
        options={employeeContext.employee.employees}
        getOptionLabel={(option) => `${option.fullName}`}
        style={{ maxWidth: 375, width: "100%", background: "white" }}
        onChange={handleSelectChange}
        getOptionDisabled={(option) =>
          option.workedToday === WorkHourStatus.NotApplicable &&
          option.workedYesterday === WorkHourStatus.NotApplicable
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Zoek een werknemer"
            variant="outlined"
          />
        )}
        renderOption={(option) => (
          <EmployeeOptionElement>
            <EmployeeOptionNameColumnElement>
              <LabelELement>naam</LabelELement>
              {option.fullName}
            </EmployeeOptionNameColumnElement>
            <EmployeeOptionColumnElement>
              <EmployeeStatusColumns
                status={option.workedToday}
                day="Vandaag"
              />
            </EmployeeOptionColumnElement>
            <EmployeeOptionColumnElement>
              <EmployeeStatusColumns
                status={option.workedYesterday}
                day={days[workDays[0]]}
              />
            </EmployeeOptionColumnElement>
            <EmployeeOptionColumnElement>
              <EmployeeStatusColumns
                status={
                  option.workedDayBeforeYesterday ||
                  WorkHourStatus.NotApplicable
                }
                day={days[workDays[1]]}
              />
            </EmployeeOptionColumnElement>
          </EmployeeOptionElement>
        )}
      />
    </EmployeeSelectContainer>
  );
};
