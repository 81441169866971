import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
`;

export const TimelineContainerElement = styled.div`
  background: #f6f6f6;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  margin-bottom: 75px;
  padding: 0 15px;
`;

export const BottomNavigationContainerElement = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: 0 0 30px 0 rgba(31, 51, 73, 0.1);
`;
