import styled from "styled-components/macro";

export const InputContainer = styled.div`
  margin-top: 25px;
  width: 100%;
`;

export const DrawerInnerContainer = styled.div`
  padding: 25px;
`;

// Important is used here to override the material ui style.
export const DrawerPaperElement = styled.div`
  border: none !important;
  box-shadow: 0 0 30px 0 rgba(31, 51, 73, 0.1) !important;
  @media (max-width: 480px) {
    width: 100%;
  }
  width: 320px;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 19px;
`;
