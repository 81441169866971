import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-bottom: 15px;
`;

export const IncidentContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const IncidentItemElement = styled.div``;

export const SummaryIconElement = styled.div`
  margin-right: 15px;
`;
