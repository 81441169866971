import { Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useContext } from "react";
import { ContainerElement } from "./styled";
import { PageContext } from "../../context/PageManager/PageManager";
import { RoutePath } from "../../../data/RoutePath";

export const Done: React.FC = () => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    setTimeout(() => pageContext.changePage(RoutePath.Code), 2000);
  }, []);

  return (
    <ContainerElement>
      <Typography gutterBottom variant="h4">U bent uitgelogd.</Typography>
      <DoneIcon style={{ fontSize: 125 }} />
    </ContainerElement>
  );
};
