import React, { useState } from "react";
import {useAuth} from "oidc-react";

type EmployeeManagerProps = {
  children: React.ReactNode;
};

type EmployeeManagerState = {
  employee: EmployeeType;
  selectedEmployee?: UserType;
  setSelectedEmployee: (uid: string) => any;
  unsetSelectedEmployee: () => any;
  login: (pin: string) => Promise<Boolean>;
  updateWorkStatus: (
    daysAgo: number,
    totalMinutes: number,
    workedMinutes: number
  ) => void;
};

export enum WorkHourStatus {
  NotApplicable = 0,
  NotStarted = 1,
  Partial = 2,
  Complete = 3,
  OverHours = 4,
}

type EmployeeType = {
  user: UserType;
  pin: string;
  // If admin is logged in this list will be filled with all employees.
  employees: UserType[];
  admin: boolean;
};

type LoginRequestType = {
  pin: string;
};

export type UserType = {
  uid: string;
  fullName: string;
  functionCode: string;
  workedYesterday: WorkHourStatus;
  workedDayBeforeYesterday: WorkHourStatus;
  workedToday: WorkHourStatus;
};

const defaultEmployee = {
  employee: {
    user: {
      uid: "",
      fullName: "",
      functionCode: "",
      workedDayBeforeYesterday: WorkHourStatus.NotApplicable,
      workedYesterday: WorkHourStatus.NotApplicable,
      workedToday: WorkHourStatus.NotApplicable,
    },
    admin: false,
    pin: "",
    employees: [],
  },
  selectedEmployee: undefined,
  login: () => {
    throw new Error("Context not initialized.");
  },
  setSelectedEmployee: () => {
    throw new Error("Context not initialized.");
  },
  unsetSelectedEmployee: () => {
    throw new Error("Context not initialized.");
  },
  updateWorkStatus: () => {
    throw new Error("Context not initialized.");
  },
};

export const EmployeeContext = React.createContext<EmployeeManagerState>(
  defaultEmployee
);

export const EmployeeManager: React.FC<EmployeeManagerProps> = ({
  children,
}) => {
  const auth = useAuth();
  const [employeeState, setEmployeeState] = useState<EmployeeType | null>(null);
  const [
    selectedEmployeeState,
    setSelectedEmployeeState,
  ] = useState<UserType | null>(null);

  /**
   * Fetches the api with login request.
   * @param pin Employee pin
   */
  const fetchLogin = async (pin: string): Promise<EmployeeType | undefined> => {
    const token = await auth?.userData?.access_token;
    const loginRequest = {
      pin: pin,
    } as LoginRequestType;

    const response = await fetch("/api/Employee/login", {
      method: "POST",
      body: JSON.stringify(loginRequest),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) return undefined;
    return await response.json();
  };

  /**
   * Tries to login a employee with given pin.
   * @param pin Employee pini
   */
  const login = async (pin: string): Promise<Boolean> => {
    const loginStatus = await fetchLogin(pin);

    if (!loginStatus) return false;
    loginStatus.pin = pin;

    // Determine if user is an admin.
    if (loginStatus.employees) {
      loginStatus.admin = true;
    } else {
      loginStatus.admin = false;
    }

    setEmployeeState(loginStatus);

    // This clears the selected employee, preventing the screen from 
    // being re-rendered with the timeline of the last selected employee 
    // on Admin re-login.
    unsetSelectedEmployee();

    return true;
  };

  /**
   * Set the employee.
   * @param id Employee id
   */
  const setSelectedEmployee = (uid: string) => {
    const foundEmployee = employeeState?.employees?.find((e) => e.uid === uid);
    if (foundEmployee) setSelectedEmployeeState(foundEmployee as UserType);
  };

  const unsetSelectedEmployee = () => {
    setSelectedEmployeeState(null);
  };

  const updateWorkStatus = (
    daysAgo: number,
    totalMinutes: number,
    workedMinutes: number
  ) => {
    let newStatus = WorkHourStatus.OverHours;

    if (workedMinutes === 0) {
      newStatus = WorkHourStatus.NotStarted;
    } else if (workedMinutes < totalMinutes) {
      newStatus = WorkHourStatus.Partial;
    } else if (workedMinutes === totalMinutes) {
      newStatus = WorkHourStatus.Complete;
    }

    const copyOfEmployee = Object.assign({}, employeeState) as EmployeeType;
    const employeeIndex = copyOfEmployee.employees.findIndex(
      (e) => e.uid === selectedEmployeeState?.uid
    );
    
    switch (daysAgo) {
      case 0: {
        copyOfEmployee.employees[employeeIndex] = ({
          ...copyOfEmployee.employees[employeeIndex],
          workedToday: newStatus,
        } as UserType)
        break;
      }
      case 1: {
        copyOfEmployee.employees[employeeIndex] = ({
          ...copyOfEmployee.employees[employeeIndex],
          workedYesterday: newStatus,
        } as UserType)
        break;
      }
      case 2: {
        copyOfEmployee.employees[employeeIndex] = ({
          ...copyOfEmployee.employees[employeeIndex],
          workedDayBeforeYesterday: newStatus,
        } as UserType)
        break;
      }
    }
    
    setEmployeeState(copyOfEmployee);
  };

  return (
    <EmployeeContext.Provider
      value={{
        employee: employeeState as EmployeeType,
        selectedEmployee: selectedEmployeeState as UserType,
        login: login,
        setSelectedEmployee: setSelectedEmployee,
        unsetSelectedEmployee: unsetSelectedEmployee,
        updateWorkStatus: updateWorkStatus,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};
