import styled from "styled-components/macro";
import { grey } from "@material-ui/core/colors";

export const EmployeeSelectContainer = styled.div`
  z-index: 2;
  margin-bottom: 45px;
  width: 100%;
`;

export const EmployeeOptionElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0;
`;

export const EmployeeOptionColumnElement = styled.div`
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmployeeOptionNameColumnElement = styled.div`
  width: 56%;
`;

export const LabelELement = styled.div`
  font-size: 12px;
  color: ${grey[500]};
`;
