import React from "react";
import { CodeInput } from "../../general/CodeInput/CodeInput";
import { ContainerElement } from "./styled";

export const Code: React.FC = () => {
  return (
    <ContainerElement>
      <CodeInput/>
    </ContainerElement>
  );
};
