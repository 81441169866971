import Button from "@material-ui/core/Button";
import React, { useContext } from "react";
import { PageContext } from "../../context/PageManager/PageManager";
import { TimeInput } from "../../general/TimeInput/TimeInput";
import { Timeline } from "../../general/Timeline/Timeline";
import { BottomNavigationContainerElement, ContainerElement, TimelineContainerElement } from "./styled";
import { RoutePath } from "../../../data/RoutePath";

export const Select: React.FC = () => {
  const pageContext = useContext(PageContext);

  const handleNextClick = () => {
    pageContext.changePage(RoutePath.Done);
  }

  return (
    <ContainerElement>
      <TimeInput />
      <TimelineContainerElement>
        <Timeline />
      </TimelineContainerElement>
      <BottomNavigationContainerElement>
        <Button onClick={handleNextClick}>
          Uitloggen
        </Button>
      </BottomNavigationContainerElement>
    </ContainerElement>
  );
};
