import React, { useContext, useState, useRef } from "react";
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager";
import { PageContext } from "../../context/PageManager/PageManager";
import { RoutePath } from "../../../data/RoutePath";
import { Typography } from "@material-ui/core";
import {
  ContainerElement,
  InputContainerElement,
  DotsElement,
  DotElement,
  CircleElement,
  HiddenInputElement
} from "./styled";

export const CodeInput: React.FC = () => {
  const employeeContext = useContext(EmployeeContext);
  const pageContext = useContext(PageContext);
  const [codeValue, setCodeValue] = useState<string>("");
  const codeInputRef = useRef<HTMLInputElement>(null);
  const [correctCode, setCorrectCode] = useState<boolean>(false);
  const [wrongCode, setWrongCode] = useState<boolean>(false);

  const handleCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (codeValue.length === 4) return;
    const value = event.currentTarget.value;
    setCodeValue(value);

    if (value.length === 4) tryLogin(value);
  };

  const tryLogin = async (pin: string) => {
    const loginStatus = await employeeContext.login(pin);
    if (loginStatus){
      setWrongCode(false);
      setCorrectCode(true);
      setTimeout(() => pageContext.changePage(RoutePath.Select), 600);
      setTimeout(() => setCodeValue(""), 600);
    }else{
      setWrongCode(true);
      setCorrectCode(false);
      setTimeout(() => {
        setCodeValue("");
        setWrongCode(false);
      }, 600);
    }
  };

  const focusInput = () => {
    if(codeInputRef)
      codeInputRef.current!.focus();
  };

  return (
    <ContainerElement>
      <Typography gutterBottom variant="h4">Personeelscode</Typography>
      <InputContainerElement onClick={focusInput}>
        <DotsElement>
          {Array.from(Array(4)).map((_, idx) => {
            return (
              <DotElement style={{
                animation: wrongCode
                ? "circleErrorSway .2s ease-in-out 2"
                : correctCode
                ? `circleCorrectSway .2s ease-in-out ${(idx + 1) / 17}s`
                : ""
              }}>
                <CircleElement filled={idx < codeValue.length && !wrongCode && !correctCode} wrongCode={wrongCode} correctCode={correctCode}/>
              </DotElement>
            );
          })}
        </DotsElement>
        <HiddenInputElement>
          <input
            ref={codeInputRef}
            onChange={handleCodeChange}
            value={codeValue}
            type="number"
            placeholder="Code"
          />
        </HiddenInputElement>
      </InputContainerElement>
    </ContainerElement>
  );
};

