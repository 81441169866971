import { Collapse, Divider, ListItemIcon, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Assignment from "@material-ui/icons/Assignment";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Folder from "@material-ui/icons/Folder";
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect, useState } from "react";
import {
  ProjectType,
  TimelineContext,
} from "../../context/TimelineManager/TimelineManager";
import {
  ContainerElement,
  ProjectInnerListContainer,
  ProjectOptionElement,
  SelectedItemContainer,
} from "./styled";
import { GENERAL_LABEL } from "../../../data/constants";

type ProjecSelectProps = {
  projects: ProjectType[];
  selectedProject?: ProjectType;
  multiselect?: boolean;
};

const useStyles = makeStyles(() => ({
  optionLi: {
    padding: 0,
  },
  popper: {
    zIndex: 99999,
  },
}));

export const ProjectSelect: React.FC<ProjecSelectProps> = ({
  projects,
  selectedProject,
  multiselect,
}) => {
  const timelineContext = useContext(TimelineContext);
  const [openSalesNumber, setOpenSalesNumber] = useState<string>("");
  const [uniqueSalesNumbers, setUniqueSalesNumbers] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ProjectType[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const uniqueSalesNumbersArray = Array.from(
      new Set(projects?.map((p) => p.salesNumber))
    );

    setUniqueSalesNumbers(uniqueSalesNumbersArray);

    if (selectedProject) {
      setSelectedItems([selectedProject]);
      handleProjectProjectsChange(selectedProject);
    }
  }, []);

  const handleSelectChange = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear") {
      setSelectedItems([]);
      timelineContext.unsetProjects();
    }
    event.preventDefault();
  };

  const handleProjectClick = (openSalesNumber: string) => {
    setOpenSalesNumber(openSalesNumber);
  };

  const handleProjectProjectsChange = (project: ProjectType) => {
    if (multiselect) {
      let copyOfSelectedItems: ProjectType[];

      // If another salesnumber already exists in the selected items.
      // We need to clear the array so only orpses in the same salesnumber
      // can be selected.
      if (
        selectedItems.length > 0 &&
        selectedItems.find((p) => project.salesNumber !== p.salesNumber)
      ) {
        copyOfSelectedItems = [];
      } else {
        copyOfSelectedItems = Object.assign([], selectedItems) as ProjectType[];
      }

      const projectIndex = selectedItems.findIndex(
        (i) => i.orpsId === project.orpsId
      );

      if (projectIndex !== -1) {
        copyOfSelectedItems.splice(projectIndex, 1);
      } else {
        copyOfSelectedItems.push(project);
      }

      setSelectedItems(copyOfSelectedItems);

      timelineContext.setSelectedProjects(copyOfSelectedItems);
    } else {
      timelineContext.setProject(
        project.id,
        project.salesNumber === GENERAL_LABEL
      );
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (multiselect && reason === "select-option") return;

    setOpen(false);
  };

  return (
    <ContainerElement>
      <Autocomplete
        classes={{
          option: classes.optionLi,
          popper: classes.popper,
        }}
        id="combo-box"
        options={
          multiselect
            ? uniqueSalesNumbers.filter(
                (s) => s.toLowerCase() !== GENERAL_LABEL
              )
            : uniqueSalesNumbers
        }
        getOptionLabel={(option) => option}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
        disableCloseOnSelect={!multiselect}
        value={selectedProject?.salesNumber}
        renderInput={(params) => (
          <TextField {...params} label="Zoek een project" variant="outlined" />
        )}
        renderOption={(option) => (
          <ProjectOptionElement>
            <ListItem button onClick={handleProjectClick.bind(null, option)}>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText primary={`${option}`} />
              {option === openSalesNumber ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {openSalesNumber !== "" && (
              <ProjectInnerListContainer>
                <Collapse component="div" in={option === openSalesNumber}>
                  <List disablePadding>
                    {projects
                      ?.filter(
                        (p) =>
                          p.salesNumber === openSalesNumber &&
                          p.description.toLowerCase() !== GENERAL_LABEL
                      )
                      .map((o) => (
                        <ListItem
                          button
                          onClick={handleProjectProjectsChange.bind(null, o)}
                        >
                          {multiselect && (
                            <Checkbox
                              checked={
                                selectedItems?.filter(
                                  (i) => i.orpsId === o.orpsId
                                ).length > 0
                              }
                            />
                          )}
                          <ListItemText
                            disableTypography
                            primary={
                              o.salesNumber === GENERAL_LABEL ? (
                                <Typography
                                  noWrap
                                >{`${o.description}`}</Typography>
                              ) : (
                                <Typography
                                  noWrap
                                >{`${o.orpsId} - ${o.salesPosition} - ${o.description}`}</Typography>
                              )
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </ProjectInnerListContainer>
            )}
          </ProjectOptionElement>
        )}
      />
      {!multiselect && selectedItems.length > 0 && (
        <SelectedItemContainer>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    Geselecteerde order
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                secondary={selectedItems[0].salesNumber}
                primary="Ordernummer"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={selectedItems[0].orpsId}
                primary="Serienummer"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={selectedItems[0].salesPosition}
                primary="Positie"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={selectedItems[0].description}
                primary="Beschrijving"
              ></ListItemText>
            </ListItem>
          </List>
        </SelectedItemContainer>
      )}
    </ContainerElement>
  );
};
