import React, { useState, SetStateAction, Dispatch } from "react";
import {useAuth} from "oidc-react";
import { GENERAL_LABEL } from "../../../data/constants";

type TimelineProps = {
  children: React.ReactNode;
};

type TimelineManagerState = {
  timeline: TimeLineType;
  projects: ProjectType[];
  selectedProjects: ProjectType[];
  functions: FunctionType[];
  selectedProject?: ProjectType;
  selectedFunction?: FunctionType;
  selectedDay: DayType;
  bookingOverHours: boolean;
  getTimeline: (pin: string, uid: string) => Promise<Boolean>;
  addTiming: (timeSlotrequest?: SaveTimeSlotRequest, generalTimeSlotrequest?: SaveGeneralWorkTimeSlotRequest) => Promise<Boolean>;
  removeTiming: (request: DeleteTimeSlotRequest, timeSlotType: TimeslotTypes) => any;
  setProject: (id: number, generalWorkProject: boolean) => any;
  unsetProject: () => any;
  unsetProjects: () => any;
  setDay: (date: Date) => any;
  unsetDay: () => any;
  setFunction: (selectedFunction: FunctionType) => any;
  unsetFunction: () => any;
  setBookingOverhours: (value: boolean) => any;
  setProjects: () => any;
  setFunctions: () => any;
  setSelectedProjects: Dispatch<SetStateAction<ProjectType[] | null>>
};

export type TimeLineType = DayType[];

export type DayType = {
  date: Date;
  totalMinutes: number;
  timeSlots: TimingType[];
};

export type TimingType = {
  id: number;
  duration: number;
  orpsId?: number;
  description: string;
  timeSlotType: TimeslotTypes;
};

export enum TimeslotTypes{
  Production = 1,
  ExtraWork = 2
}

export type ProjectType = {
  id: number;
  description: string;
  orpsId: number;
  salesNumber: string;
  salesPosition: string;
};

export type GeneralTaskLookupType = {
  id: number;
  lookupText: string;
};

type GetTimelineRequest = {
  pin: string;
  employeeUid: string;
};

export type SaveTimeSlotRequest = {
  pin: string;
  employeeUid: string;
  date: Date;
  duration: number;
  orpsId: number;
  functionCodeId: number;
};

export type SaveGeneralWorkTimeSlotRequest = {
  pin: string;
  employeeUid: string;
  date: Date;
  duration: number;
  lookupText: string;
};

export type DeleteTimeSlotRequest = {
  pin: string;
  employeeUid: string;
  id: number;
};

export type FunctionType = {
  id: number;
  description: string;
  code: string;
};

const defaultTimeline = {
  selectedProjects: [],
  timeline: [
    {
      date: new Date(),
      totalMinutes: 0,
      timeSlots: []
    }
  ],
  projects: [],
  functions: [],
  selectedProject: undefined,
  selectedDay: {
    id: 0,
    date: new Date(),
    totalMinutes: 0,
    timeSlots: []
  },
  selectedFunction: undefined,
  bookingOverHours: false,
  getTimeline: () => {
    throw new Error("Context not initialized.");
  },
  addTiming: () => {
    throw new Error("Context not initialized.");
  },
  removeTiming: () => {
    throw new Error("Context not initialized.");
  },
  setProject: () => {
    throw new Error("Context not initialized.");
  },
  unsetProject: () => {
    throw new Error("Context not initialized.");
  },
  unsetProjects: () => {
    throw new Error("Context not initialized.");
  },
  setDay: () => {
    throw new Error("Context not initialized.");
  },
  unsetDay: () => {
    throw new Error("Context not initialized.");
  },
  setFunction: () => {
    throw new Error("Context not initialized.");
  },
  unsetFunction: () => {
    throw new Error("Context not initialized.");
  },
  setBookingOverhours: () => {
    throw new Error("Context not initialized.");
  },
  setProjects: () => {
    throw new Error("Context not initialized.");
  },
  setFunctions: () => {
    throw new Error("Context not initialized.");
  },
  setSelectedProjects: () => {
    throw new Error("Context not initialized.");
  }
};

export const TimelineContext = React.createContext<TimelineManagerState>(
  defaultTimeline
);

export const TimelineManager: React.FC<TimelineProps> = ({ children }) => {
  const auth = useAuth();
  const [timelineState, setTimelineState] = useState<null | DayType[]>(null);
  const [bookingOverhoursState, setBookingOverhoursState] = useState<boolean>(false);
  const [projectsState, setProjectsState] = useState<null | ProjectType[]>(
    null
  );
  const [selectedProjects, setSelectedProjects] = useState<null | ProjectType[]>(
    null
  );
  const [functionsState, setFunctionsState] = useState<null | FunctionType[]>(
    null
  );
  const [selectedFunctionState, setSelectedFunctionState] = useState<null | FunctionType>(
    null
  );
  const [
    selectedProjectState,
    setSelectedProjectState
  ] = useState<null | ProjectType>(null);
  const [selectedDayState, setSelectedDayState] = useState<null | DayType>(
    null
  );

  /**
   * Fetches the timeline by user id.
   * @param pin Employee pin
   * @param uid Employee uid
   */
  const fetchTimeline = async (pin: string, uid: string): Promise<TimeLineType | undefined> => {
    const token = await auth?.userData?.access_token;
    const getTimelineRequest = {
      pin: pin,
      employeeUid: uid
    } as GetTimelineRequest;

    const response = await fetch("/api/Timeline/get", {
      method: "POST",
      body: JSON.stringify(getTimelineRequest),
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return undefined;
    return await response.json(); 
  };

  /**
   * gets the timeline by using the fetching method.
   * If it fails it will return true else false.
   */
  const getTimeline = async (pin: string, uid: string): Promise<Boolean> => {
    const fetchedTimeline = await fetchTimeline(pin, uid);
    if(!fetchedTimeline) return false;
    setTimelineState(fetchedTimeline);
    return true;
  }

  /**
   * Set the project.
   * @param id Project id
   */
  const setProject = (id: number, generalWorkProject: boolean) => {
    const foundProject = generalWorkProject ? 
      projectsState?.find(e => e.id === id && e.salesNumber === GENERAL_LABEL) : 
      projectsState?.find(e => e.id === id && e.salesNumber !== GENERAL_LABEL);

    setSelectedProjectState(foundProject as ProjectType);
  };

  const unsetProject = () => {
    setSelectedProjectState(null);
  };

  const unsetProjects = () => {
    setSelectedProjects(null);
  };

  /**
   * Set the day.
   * @param id Day id
   */
  const setDay = (date: Date) => {
    const foundDay = timelineState?.find(e => e.date === date);
    setSelectedDayState(foundDay as DayType);
  };

  const unsetDay = () => {
    setSelectedDayState(null);
  };

  /**
   * Set the project.
   * @param id Project id
   */
  const setFunction = (selectedFunction: FunctionType) => {
    const foundFunction = functionsState?.find(e => e.id === selectedFunction.id);
    setSelectedFunctionState(foundFunction as FunctionType);
  };

  const unsetFunction = () => {
    setSelectedFunctionState(null);
  };

  /**
   * Fetch the api to add a timing.
   * @param id day id
   * @param timing Timing data
   */
  const fetchAddTiming = async (request: SaveTimeSlotRequest): Promise<TimingType | undefined> => {
    const token = await auth?.userData?.access_token;

    const response = await fetch("/api/Timeline/save", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return undefined;
    return await response.json(); 
  };

  const fetchAddGeneralWorkTiming = async (request: SaveGeneralWorkTimeSlotRequest): Promise<TimingType | undefined> => {
    const token = await auth?.userData?.access_token;

    const response = await fetch("/api/GeneralTasks", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return undefined;
    return await response.json(); 
  };

  const addTiming = async (timeSlotrequest?: SaveTimeSlotRequest, generalTimeSlotrequest?: SaveGeneralWorkTimeSlotRequest): Promise<Boolean> => {
    if (!selectedDayState || !selectedProjectState)
      throw new Error("Some parameters are not selected.");

    const request = timeSlotrequest ?? generalTimeSlotrequest;

    if(!request) return false;

    // The total set time of the current selected day.
    const currentMinutesOfDay = selectedDayState.timeSlots.reduce(
      (a, b) => a + b.duration,
      0
    );

    // If the total set time of the day plus the timing that is being added is greater
    // then the total minutes of the day return.
    if (!bookingOverhoursState && currentMinutesOfDay + request.duration > selectedDayState.totalMinutes) return false;

    const copyOfTimeline = Object.assign([], timelineState) as DayType[];
    const copyOfSelectedDay = Object.assign({}, selectedDayState) as DayType;
    const dayIndex = copyOfTimeline.findIndex(
      d => d.date === copyOfSelectedDay.date
    );

    let timeSlotItem: TimingType | undefined;

    if(timeSlotrequest){
      timeSlotItem = await fetchAddTiming(timeSlotrequest);
    }else if(generalTimeSlotrequest){
      timeSlotItem = await fetchAddGeneralWorkTiming(generalTimeSlotrequest);
    }

    if(timeSlotItem) {
      copyOfSelectedDay.timeSlots.unshift(timeSlotItem);
      copyOfTimeline[dayIndex] = copyOfSelectedDay;
      setTimelineState(copyOfTimeline);
      return true;
    }

    return false;
  };

  /**
   * Fetch the api to remove a timing.
   * @param id Employee id
   */
  const fetchRemoveTiming = async (request: DeleteTimeSlotRequest) => {
    const token = await auth?.userData?.access_token;

    const response = await fetch("/api/Timeline/delete", {
      method: "POST",
      body: JSON.stringify({
        ...request,
        timeSlotId: request.id
      }),
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) throw new Error("Something went wrong while deleting timeslot.");
  };

  const fetchRemoveGeneralTiming = async (request: DeleteTimeSlotRequest) => {
    const token = await auth?.userData?.access_token;

    const response = await fetch("/api/GeneralTasks", {
      method: "DELETE",
      body: JSON.stringify({
        ...request,
        generalTaskId: request.id
      }),
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) throw new Error("Something went wrong while deleting general timeslot.");
  };


  const removeTiming = async (request: DeleteTimeSlotRequest, timeSlotType: TimeslotTypes) => {
    const copyOfTimeline = Object.assign([], timelineState) as DayType[];
    const foundDay = copyOfTimeline.find(d =>
      d.timeSlots.map(t => t.id).includes(request.id)
    );
    const itemToRemoveIndex = foundDay?.timeSlots.findIndex(t => t.id === request.id);

    if (itemToRemoveIndex !== undefined)
      foundDay?.timeSlots.splice(itemToRemoveIndex, 1);
    else throw new Error("Item to remove not found.");

    const totalDuration = foundDay?.timeSlots.reduce(
      (a, b) => a + b.duration,
      0) as number;

    setTimelineState(copyOfTimeline);

    if(timeSlotType === TimeslotTypes.Production){
      await fetchRemoveTiming(request);
    }else{
      await fetchRemoveGeneralTiming(request);
    }

    if (bookingOverhoursState && foundDay && foundDay.totalMinutes > totalDuration )
    {
      setBookingOverhoursState(false);
    }
  };

  const fetchProjects = async (): Promise<ProjectType[] | null> => {
    const token = await auth?.userData?.access_token;
    const response = await fetch("/api/Project/get", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return null;
    return await response.json(); 
  };

  const fetchGeneralTasks = async (): Promise<GeneralTaskLookupType[] | null> => {
    const token = await auth?.userData?.access_token;
    const response = await fetch("/api/GeneralTasks", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return null;
    return await response.json(); 
  };
  
  const fetchFunctions = async (): Promise<FunctionType[] | null> => {
    const token = await auth?.userData?.access_token;
    const response = await fetch("/api/FunctionCode/get", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) return null;
    return await response.json(); 
  };

  const convertGeneralTasksLookupsToProjects = async (): Promise<ProjectType[] | undefined> => {
    const fetchedGeneralTasks = await fetchGeneralTasks();

    let generalTasksAsProjects = fetchedGeneralTasks?.map(g => ({
      id: g.id,
      description: g.lookupText,
      orpsId: -1,
      salesNumber: GENERAL_LABEL,
      salesPosition: "",
    } as ProjectType));

    if(!generalTasksAsProjects) return;

    generalTasksAsProjects = [{
      id: -1,
      description: GENERAL_LABEL,
      orpsId: -1,
      salesNumber: GENERAL_LABEL,
      salesPosition: "",
    }, ...generalTasksAsProjects];

    return generalTasksAsProjects;
  }

  const setProjects = async () => {
    const fetchedProjects = await fetchProjects();
    const generalTaskProjects = await convertGeneralTasksLookupsToProjects();

    setProjectsState(generalTaskProjects && fetchedProjects ? [...generalTaskProjects, ...fetchedProjects] : fetchedProjects);
  };

  const setFunctions = async () => {
    const fetchedFunction = await fetchFunctions();
    setFunctionsState(fetchedFunction);
  };

  const setBookingOverhours = (value: boolean) => {
    setBookingOverhoursState(value);
  }

  return (
    <TimelineContext.Provider
      value={{
        timeline: timelineState as TimeLineType,
        projects: projectsState as ProjectType[],
        selectedProjects: selectedProjects as ProjectType[],
        functions: functionsState as FunctionType[],
        selectedProject: selectedProjectState as ProjectType,
        selectedDay: selectedDayState as DayType,
        selectedFunction: selectedFunctionState as FunctionType,
        bookingOverHours: bookingOverhoursState,
        getTimeline: getTimeline,
        addTiming: addTiming,
        removeTiming: removeTiming,
        setProject: setProject,
        unsetProject: unsetProject,
        setDay: setDay,
        unsetDay: unsetDay,
        setFunction: setFunction,
        unsetFunction: unsetFunction,
        setBookingOverhours: setBookingOverhours,
        setFunctions: setFunctions,
        setProjects: setProjects,
        setSelectedProjects: setSelectedProjects,
        unsetProjects: unsetProjects,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};
