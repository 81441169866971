import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, { useContext, useEffect, useState } from "react";
import { EmployeeContext } from "../../context/EmployeeManager/EmployeeManager";
import { TimelineContext } from "../../context/TimelineManager/TimelineManager";
import { ContainerElement } from "./styled";
import { GENERAL_LABEL } from "../../../data/constants";

export const FunctionSelect: React.FC = () => {
  const timelineContext = useContext(TimelineContext);
  const employeeContext = useContext(EmployeeContext);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  useEffect(() => {
    const id = employeeContext.selectedEmployee
      ? timelineContext.functions?.find(
          (f) => employeeContext.selectedEmployee?.functionCode === f.code
        )?.id
      : timelineContext.functions?.find(
          (f) => employeeContext.employee.user.functionCode === f.code
        )?.id;
    setSelectedValue(id ? id : null);
    if (id) {
      const selectedFunction = timelineContext.functions?.find(
        (f) => f.id === id
      );
      if (selectedFunction) timelineContext.setFunction(selectedFunction);
    }
  }, [timelineContext.selectedDay]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValue(event.target.value as number);
    const selectedFunction = timelineContext.functions.find(
      (f) => f.id === (event.target.value as number)
    );
    if (selectedFunction) timelineContext.setFunction(selectedFunction);
  };

  return (
    <ContainerElement>
      <FormControl style={{ width: "100%" }}>
        <Select
          style={{ width: "100%" }}
          labelId="function-select-label"
          id="function-select"
          variant="outlined"
          value={selectedValue}
          onChange={handleSelectChange}
          disabled={
            timelineContext.selectedProject?.salesNumber === GENERAL_LABEL
          }
        >
          {timelineContext.functions?.map((f, idx) => (
            <MenuItem key={idx} value={f.id}>
              {f.description}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Medewerker functie</FormHelperText>
      </FormControl>
    </ContainerElement>
  );
};
