import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #F6F6F6; 
`;